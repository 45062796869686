@import '../../assets/sass/variables';
@import '../../assets/sass/mixins';
@import '../../assets/sass/utilities';

.about {
	background: var(--bg-dark);
	padding: 10rem 0;

	&__flex {
		padding: 10rem 0;
		display: flex;
		flex-direction: row;
		justify-content: space-around;

		@include respond(tablet-large) {
			flex-direction: column;
		}
	}
	&__card {
		box-shadow: 0 2px 6px rgba(8, 112, 100, 0.1);

		width: 22%;

		font-size: 1.5rem;
		padding: 2.5rem;
		margin: 3rem 0;
		text-align: center;
		border-radius: 3px;
		transition: all 0.3s;

		&:hover {
			transform: translateY(-1.5rem) scale(1.05);
		}

		@include respond(tablet-large) {
			width: 90%;
			margin: 2rem auto;
		}
	}

	&__icon {
		height: 5rem;
		fill: blueviolet;
		width: 100%;
	}
}
