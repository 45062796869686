@import '../../assets/sass/mixins';
.error404 {
	display: flex;
	justify-content: center;
	align-items: center;

	flex-direction: column;

	min-height: 50vh;

	&--figure {
		display: flex;
		justify-content: center;
	}
	&--img {
		@include ResponsiveImage();
	}
}
