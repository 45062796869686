@import '../../assets/sass/variables';
@import '../../assets/sass/mixins';

.footer {
	background: var(--bg-dark);
	padding: 2rem 0;
	font-size: 1.4rem;
	margin-top: 5rem;

	@include respond(tablet-large) {
		width: 100%;
	}

	&__logo-box {
		text-align: center;
		margin-bottom: 4rem;
	}

	&__logo {
		height: 5rem;
		width: auto;
	}

	&__navigation {
		align-items: center;
	}

	&__list {
		list-style: none;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}

	&__item {
		display: inline-block;

		&:not(:last-child) {
			margin-right: 1.5rem;
		}
	}

	&__link {
		&:link,
		&:visited {
			text-decoration: none;
			color: var(--color-boulder);
			display: inline-block;
			text-transform: uppercase;
			transition: all 0.2s;
		}

		&:hover,
		&:active {
			color: var(--color-primary);
		}
	}

	&__description {
		padding: 0 10rem;

		@include respond(tablet-large) {
			padding: 0 5rem;
		}

		@include respond(mobile) {
			padding: 0 2rem;
		}
	}

	&__copyright {
		padding-top: 2rem;
		width: 80%;
		margin: 0 auto;
		text-align: center;
		color: white;
	}

	&__social {
		margin: 5rem auto;
		align-items: center;

		&--icons {
			list-style: none;
			display: flex;
			margin: 0 auto;
			align-items: center;
			justify-content: center;
		}

		&--list {
			list-style: none;
			width: 5rem;
			align-content: center;
		}

		&--link {
			text-decoration: none;
		}

		&--icon {
			height: 2rem;
			fill: white;
			width: 100%;
		}
	}
}
