@import '../../assets/sass/mixins';

.team {
	padding: 5rem;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-gap: 3rem;
	grid-auto-rows: 1fr;

	@include respond(tablet-large) {
		grid-template-columns: repeat(3, 1fr);
		padding: 5rem 2rem;
	}

	@include respond(tablet) {
		padding: 5rem 1rem;
		grid-template-columns: repeat(2, 1fr);
	}

	@include respond(tablet-small) {
		grid-template-columns: auto;
	}

	&__card {
		padding: 2rem;
		display: flex;
		flex-direction: column;
		box-shadow: 0 2px 6px rgba(8, 112, 100, 0.1);
	}

	&__member--image {
		@include ResponsiveImage();
	}

	&__member--name {
		font-size: 1.4rem;
		font-weight: 300;
		color: var(--color-white);
		font-weight: 600;
		line-height: 1.6;
	}

	&__member--title {
		font-size: 1.3rem;
		color: var(--color-boulder);
	}
}
