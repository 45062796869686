@import '../../assets/sass/variables';
@import '../../assets/sass/mixins';

.navbar {
	position: sticky;
	height: 10rem;

	// On Dark theme
	background: var(--bg-dark);
	// On light theme
	// background: var(--bg-light);

	z-index: var(--default-z-index);

	top: 0;

	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: 0 4rem;

	@include respond(mobile-large) {
		padding: 0 1rem 0 2rem;
	}

	&__logo {
		&-img {
			margin: auto 0;
			width: auto;
			height: 3rem;
		}
	}

	&__list {
		// On Dark theme
		color: var(--color-white);
		// On light theme
		// color: var(--color-portGore);
		font-size: 1.5rem;
		font-weight: 600;
		list-style-type: none;
		display: block;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 100%;

		@include respond(tablet) {
			position: absolute;
			right: 0;
			top: 100px;
			height: 100vh;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			// On Dark theme
			background: var(--bg-dark);
			// On light theme
			// background: var(--bg-light);

			// background: transparent;
			z-index: 1500;

			opacity: 0;
			width: 0;
			transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
		}

		&-item {
			display: inline-block;
			text-align: center;
			text-transform: capitalize;

			padding: 1rem 1.5rem;
		}

		&-link {
			@include ResetLink();

			&:hover,
			&:active {
				color: var(--color-primary);
			}
		}
	}

	// icon
	.menu-icon {
		display: none;
		margin: auto 0;

		@include respond(tablet) {
			display: inline-block;
			cursor: pointer;
			display: inline-block;
			padding: 28px 20px;
			position: relative;
			user-select: none;
		}
	}

	.menu-icon .navicon {
		// On Dark theme
		background: var(--color-white);
		// On light theme
		// background: #333;

		display: block;
		height: 3px;
		position: relative;
		transition: background 0.2s ease-out;
		width: 25px;
	}

	.menu-icon .navicon:before,
	.menu-icon .navicon:after {
		// On Dark theme
		background: var(--color-white);
		// On light theme
		// background: #333;
		content: '';
		display: block;
		height: 100%;
		position: absolute;
		transition: all 0.2s ease-out;
		width: 100%;
	}

	.menu-icon .navicon:before {
		top: 8px;
	}

	.menu-icon .navicon:after {
		top: -8px;
	}

	// menu btn
	.menu-btn {
		// Hide the Checkbox
		display: none;
	}

	// Functionality
	.menu-btn:checked ~ .navbar__list {
		// Do Transform Here
		opacity: 1;
		width: 100%;
	}

	.menu-btn:checked ~ .menu-icon .navicon {
		background: transparent;
	}

	.menu-btn:checked ~ .menu-icon .navicon:before {
		transform: rotate(-45deg);
	}

	.menu-btn:checked ~ .menu-icon .navicon:after {
		transform: rotate(45deg);
	}

	.menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
	.menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
		top: 0;
	}
}
