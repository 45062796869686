:root {
	// colors
	// Color names were gotten from this site http://chir.ag/projects/name-that-color/
	--color-purpleHeart: #8a2be2;
	--color-portGore: #222244;
	--color-boulder: #777777;
	--color-amaranth: #f13757;
	--color-white: #fff;
	--color-regentGray: #8899a6;
	--color-mirage: #15202b;

	--color-white: #fff;
	--color-black: #000;

	--color-primary: var(--color-amaranth);
	--bg-light: var(--color-white);
	--bg-dark: var(--color-mirage);
	--bg-dark-bright: #15203a;

	// shadows
	--shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
	--shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);

	// font
	--default-font-size: 1.4rem;
	--nunito-sans-font: 'Nunito Sans', sans-serif;
	--nunito-font: 'Nunito', sans-serif;

	// z-indexes
	--default-z-index: 1000;
}
