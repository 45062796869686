@import '../../assets/sass/mixins';

.work {
	padding: 5rem;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 3rem;
	grid-auto-rows: 1fr;

	@include respond(tablet) {
		padding: 5rem 0;
		grid-template-columns: auto;
	}

	&__card {
		box-shadow: 0 2px 6px rgba(8, 112, 100, 0.1);

		padding: 2rem 4rem;
		height: 100%;

		&--figure {
			background-size: cover;
			// height: 25rem;
		}

		&--image {
			@include ResponsiveImage();
		}

		&--details {
			padding: 3rem 0;
		}
	}
}
