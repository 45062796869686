// ----------------------- base ---------------------------- //
*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	// padding: 0 1rem;
	width: 100vw;
	overflow-x: hidden !important;

	padding: 0;

	background: var(--bg-dark);

	@include respond(mobile-large) {
		padding: 0;
	}
}

::selection {
	background-color: var(--color-primary);
	color: var(--color-white);
}

// TODO REFACTOR
.body-wrapper {
	@include respond(tablet) {
		overflow-x: hidden;
	}
}
