@import '../../assets/sass/mixins';

.jumbotron {
	display: flex;

	padding: 0 4rem;
	flex-direction: row;

	// background-image: linear-gradient(
	// 		to right,
	// 		rgba(255, 255, 255, 0.6),
	// 		rgba(250, 249, 249, 0.95)
	// 	),
	// 	url('../../assets/illustrations/cool-bg.png');
	// background-position: 0% 50%;
	// background-size: cover;
	// background-repeat: no-repeat;

	background: var(--bg-dark);

	@include respond(tablet-large) {
		flex-direction: column-reverse;
		padding: 0 2.5rem;
		background-position: 50% 50%;
	}

	@include respond(tablet) {
		flex: 0 0 70%;
		padding: 0 1rem;
	}

	@include respond(mobile-large) {
		text-align: center;
	}

	&__cta {
		flex: 0 0 50%;
		padding: 10rem 2rem 0 2rem;

		&-group {
			a {
				display: inline-block;
			}
		}
	}

	&__illustration {
		flex: 0 0 50%;
		padding: 4rem;

		&-img {
			@include ResponsiveImage();

			filter: drop-shadow(0 4rem 8rem rgba(8, 112, 184, 0.5));
		}
	}
}
