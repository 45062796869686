@import '../../assets/sass/variables';
@import '../../assets/sass/mixins';

.features {
	display: flex;

	padding: 0 4rem;
	flex-direction: row;

	@include respond(tablet-large) {
		flex-direction: column-reverse;
		text-align: center;
	}

	&__cta {
		flex: 0 0 50%;
		padding: 10rem 2rem 0 2rem;

		@include respond(tablet-large) {
			text-align: center;
		}

		&-desc {
			font-size: 1.6rem;
			color: #224;
			font-family: 'Nunito Sans', sans-serif;
			font-weight: 600;
			line-height: 1.5;
		}

		&-group {
			a {
				display: inline-block;
			}
		}
	}

	&__illustration {
		flex: 0 0 50%;
		padding: 4rem;

		&-img {
			@include ResponsiveImage();

			@include respond(mobile) {
				width: 10rem;
			}
		}

		@include respond(tablet) {
			display: none;
		}

		filter: drop-shadow(0 3rem 3rem rgba(8, 112, 184, 0.2));
	}
}
