// This File contains global site typography

// We are using relative units in this site - rem units
html {
	font-size: 62.5%; // 1rem = 10px
}
body {
	font-size: var(--default-font-size);
	font-family: var(--nunito-sans-font);

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.heading {
	&__super {
		font-family: var(--nunito-font);
		font-weight: 900;
		line-height: 1.5;
		font-size: 40px;

		&--white {
			color: var(--color-white);
		}
	}
	&__main {
		font-family: var(--nunito-font);
		font-weight: 700;
		line-height: 1.125;
		font-size: 36px;

		&--white {
			color: var(--color-white);
		}
	}
	&__secondary {
		font-family: var(--nunito-font);
		font-weight: 700;
		line-height: 1.125;
		font-size: 24px;

		&--white {
			color: var(--color-white);
		}
		&--purpleHeart {
			color: var(--color-purpleHeart);
		}
		&--regentGray {
			color: var(--color-regentGray);
		}
	}
}

.text__content {
	font-size: var(--default-font-size);
	&--cta {
		font-size: 1.8rem;
		font-family: var(--nunito-sans-font);
		font-weight: 600;
		line-height: 1.5;
	}
	&--normal {
		font-size: 1.6rem;
		font-family: var(--nunito-font);
		font-weight: 400;
		line-height: 2;
	}

	&--purpleHeart {
		color: var(--color-purpleHeart);
	}
	&--portGore {
		color: var(--color-portGore);
	}
	&--boulder {
		color: var(--color-boulder);
	}
	&--amaranth {
		color: var(--color-amaranth);
	}
	&--white {
		color: var(--color-white);
	}
	&--regentGray {
		color: var(--color-regentGray);
	}
	&--mirage {
		color: var(--color-mirage);
	}
}
