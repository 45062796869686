// ----------------------- utilities ------------------------- //
.u-center-text {
	text-align: center !important;
}

.u-margin-bottom-big {
	margin-bottom: 8rem !important;
}

.u-margin-bottom-medium {
	margin-bottom: 4rem !important;
}

.u-margin-bottom-small {
	margin-bottom: 1.5rem !important;
}

.u-margin-top-small {
	margin-top: 1.5rem !important;
}

.u-margin-top-medium {
	margin-top: 4rem !important;
}

.u-margin-top-big {
	margin-top: 8rem !important;
}

.u-margin-top-huge {
	margin-top: 10rem !important;
}

// Fix Top Navbar Hover

.u-nav-link-hover {
	&:hover {
		color: var(--color-white);
		background-color: var(--color-primary);
	}
}
