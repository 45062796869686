@import '../../assets/sass/variables';
@import '../../assets/sass/mixins';

.talk {
	// margin: 0 4rem;

	display: flex;
	flex-direction: row;
	justify-content: space-around;

	@include respond(mobile-large) {
		flex-direction: column;
	}

	background: var(--bg-dark-bright);
	// background: #15203f;

	// background-image: linear-gradient(
	// 	to bottom right,
	// 	rgba(177, 210, 227, 0.5),
	// 	rgba(220, 237, 240, 0.9)
	// );
}
